import {
    XyDataSeries,
    HorizontalLineAnnotation,
    ELabelPlacement,
    EAnnotationLayer,
    VerticalLineAnnotation,
    SciChartSurface,
    ZoomPanModifier,
    MouseWheelZoomModifier,
    ZoomExtentsModifier,
    RolloverModifier,
    RubberBandXyZoomModifier,
    EXyDirection,
    EExecuteOn,
    FastImpulseRenderableSeries,
    LegendModifier,
    EAutoRange,
    NumberRange,
    EAxisAlignment,
    NumericAxis,
    DateTimeNumericAxis,
    CursorModifier,
    IRenderableSeries,
    FastLineRenderableSeries,
    ISciChartSurfaceBase,
    TrianglePointMarker,
    UpdateSuspender,
    XyyDataSeries,
    FastBandRenderableSeries,
    EDataSeriesType,
    LeftAlignedOuterVerticallyStackedAxisLayoutStrategy,
    LeftAlignedOuterAxisLayoutStrategy,
    } from "scichart";
  import { IInitResult, TInitFunction } from "scichart-react";
  import { enMessages } from "../../../../../messages/en-US";
  import { ColumnPaletteProvider } from "../PaletteProvider";
  import moment from "moment";
  import { axisLabelDateFormatter } from "../../../../Shared/SciChart/SmartAxis/axisLabelDateFormatter";
  import { GetChartTheme, getTooltipDataTemplate } from "../../../../Shared/SciChart/ChartConfiguration";
import { LocalizationService } from "@progress/kendo-react-intl";
import { DateTickProvider } from "../../../../Shared/SciChart/SmartAxis/DateTickProvider";
import { SmartDateTZLabelProvider } from "../../../../Shared/SciChart/SmartAxis/SmartDateTZLabelProvider";
import { createDateTzConverter } from "../../../../Shared/SciChart/SmartAxis/timezones";
  
  export interface TActivityPressureChartConfigResult<TSurface extends ISciChartSurfaceBase> extends IInitResult<TSurface> {
    setAutoRange: (value: boolean) => void,
    setIsSingleMode: (value: boolean) => void
  }
  
  export type TActivityPressureConfigFunc = TInitFunction<SciChartSurface, TActivityPressureChartConfigResult<SciChartSurface>>;

  export interface Props {
    chartData: any;
  }

  export interface ChartOptions {
    isAutoRange: boolean;
    isBarChartOn: boolean;
    visibleRange: { min: number | undefined; max: number | undefined; };
    setVisibleRange: (range: any) => void;
    setVisibleSeriesActivityPressure: (series: any, isChecked: boolean) => void;
    visibleSeriesActivityPressure: { [key: string]: boolean;}
    isSinglePressureChart: boolean;
    aggregator: string;
  }
  
  export const getChartAPI = (props:Props, chartOptions:ChartOptions, localizationService: LocalizationService, currentLanguage: { locale: string; localeId: string; }) => {

    const endFilterDateUnix = moment(props.chartData.dateFilter.endDate).unix();
    const startFilterDateUnix = moment(props.chartData.dateFilter.startDate).unix();

    const theme = GetChartTheme();  
    let setAutoRangeCallback: (arg0: boolean) => void;
    let setIsSingleModeCallback: (arg0: boolean) => void;
  
    const configAfterInit = (initResult) => {
        setAutoRangeCallback = initResult.setAutoRange;
        setIsSingleModeCallback = initResult.setIsSingleMode;
    } 
  
    const setAutoRange = (value: boolean) => {
        if (setAutoRangeCallback !== undefined) {
            setAutoRangeCallback(value);
        }
    }
  
    const setIsSingleMode = (value:boolean) => {
        if (setIsSingleModeCallback !== undefined) {
            setIsSingleModeCallback(value);
        }
    }

    const initChart : TActivityPressureConfigFunc = async (rootElement: string | HTMLDivElement) => {

    const {sciChartSurface, wasmContext} = await SciChartSurface.create(rootElement, {theme});

      // #region Tooltip Template
      const getTooltipPressureLegendTemplate = (seriesInfo: any, tooltipTitle: string, tooltipLabelX: string, tooltipLabelY: string) => {
          const lines: string[] = [];
          if (seriesInfo.dataSeriesType === EDataSeriesType.Xy) {
          const hlcSeriesInfo = seriesInfo as any;
          lines.push(`x: ${seriesInfo.formattedXValue}`);
          if(hlcSeriesInfo.pointMetadata !== null) {
          if (typeof hlcSeriesInfo.pointMetadata === 'object' && 'label' in hlcSeriesInfo.pointMetadata) {
              const labelParts =  hlcSeriesInfo.pointMetadata.label.split(',');
              lines.push(`${labelParts[0]}`);
              lines.push(`${localizationService.toLanguageString('custom.mean', enMessages.custom.mean)}: ${seriesInfo.formattedYValue}`);
              lines.push(`${labelParts[1]}`);
              lines.push(`${labelParts[2]}`);
          }
          }}
          return lines;
      };

      // #endregion

      // #region Setup the XAxis
      const xAxis = new DateTimeNumericAxis(wasmContext, { 
        drawMajorBands: false, 
        drawMinorGridLines: false, 
        drawMajorGridLines: false, 
        visibleRange: new NumberRange(chartOptions.visibleRange.min, chartOptions.visibleRange.max),
        visibleRangeLimit: new NumberRange(startFilterDateUnix, endFilterDateUnix),
        growBy: new NumberRange(0.05, 0.05),
        labelStyle: {fontFamily:"Roboto", fontSize:12,}}
      );

      const updateSettings = (xAxis: NumericAxis) => {
        const timezone = moment.tz.guess();
        // Calculate ticks which respect the time zone
        xAxis.tickProvider = new DateTickProvider(wasmContext, timezone);
        // Draws nice axis labels timezone and locale specific
        xAxis.labelProvider = new SmartDateTZLabelProvider(timezone,true, currentLanguage?.localeId);
        const dateTzConverter = createDateTzConverter(timezone);
        // This is needed for Cursor and Rollover chart modifiers
        xAxis.labelProvider.formatCursorLabel = timestamp =>
          chartOptions.aggregator === 'none' ? axisLabelDateFormatter.toFullDateTime(timestamp, dateTzConverter, currentLanguage?.localeId) : axisLabelDateFormatter.toFullDateOnly(timestamp, dateTzConverter, currentLanguage?.localeId);
      };
      // Setting timezone to local system
      updateSettings(xAxis);

        // Overrides getDeltaFromRange to get nice major and minor deltas for the ticks
      xAxis.deltaCalculator.getDeltaFromRange = axisLabelDateFormatter.getDeltaFromRange;
     
      xAxis.visibleRangeChanged.subscribe((args) => { chartOptions.setVisibleRange(args?.visibleRange); })
      sciChartSurface.xAxes.add(xAxis);

      // #endregion

      // #region Setup the YAxis

      // Create the temperature axis first so its at the top of the stack in multiple pressure chart mode
      const yAxisTemperature = new NumericAxis(wasmContext,{
          axisAlignment: EAxisAlignment.Left, 
          stackedAxisLength: "20%",
          id: "Temp",
          drawMajorBands: false,
          drawMinorGridLines: true, 
          drawMajorGridLines: false, 
          labelPostfix: " °C",
          labelPrecision: 2,
          growBy: new NumberRange(0, 0.2),
          labelStyle: {fontFamily:"Roboto", fontSize:12}});
        
        yAxisTemperature.visibleRangeChanged.subscribe(args => {
          if (args?.visibleRange.min !== 0) {
            yAxisTemperature.visibleRange = new NumberRange(0, args?.visibleRange.max);
          }
        });
    
      const setAutoRangeTemperature = (value: boolean) => {
  
          yAxisTemperature.autoRange = value ? EAutoRange.Always : EAutoRange.Never;
  
          if (!value) {
              yAxisTemperature.visibleRange = new NumberRange(0, Math.max(...props.chartData.pressureEvents?.temperatureData) * 1.20);
          }
      }

      sciChartSurface.yAxes.add(yAxisTemperature);

      // Create the pressure axis second so its in the middle of the stack in multiple pressure chart mode
      const yAxisPressure = new NumericAxis(wasmContext,{
        axisAlignment: EAxisAlignment.Right,
        drawMajorBands: false,
        drawMinorGridLines: true, 
        drawMajorGridLines: false, 
        labelPostfix:" psi",
        labelPrecision: 0,
        growBy: new NumberRange(0.2, 0.2),
        labelStyle: {fontFamily:"Roboto", fontSize:12}});
    
        const setAutoRangePressure = (value: boolean) => {
          yAxisPressure.autoRange = value ? EAutoRange.Always : EAutoRange.Never;
      
          if (!value) {
            const maxPressure = Math.max(...props.chartData.pressureEvents?.pressureDataMax);
            const minPressure = Math.min(...props.chartData.pressureEvents?.pressureDataMin);
            const maxRange = maxPressure * 1.20;
            const minRange = minPressure < 0 ? minPressure * 1.2 : 0;
              
              yAxisPressure.visibleRange = new NumberRange(minRange, maxRange);
          }
      }
      
      yAxisPressure.visibleRangeChanged.subscribe(args => {
        const minPressure = Math.min(...props.chartData.pressureEvents?.pressureDataMin);
        if (minPressure < 0) {
            return;
        }
        if (args?.visibleRange.min !== 0 && minPressure >= 0) {
            yAxisPressure.visibleRange = new NumberRange(0, args?.visibleRange.max);
        }
    });
    
      
      sciChartSurface.yAxes.add(yAxisPressure);

      // Create the flow axis last so its at the bottom of the stack in multiple pressure chart mode
      const yAxisFlow = new NumericAxis(wasmContext,{
        id:"flowAxis",
        axisAlignment: EAxisAlignment.Right,
        drawMajorBands: false,
        drawMinorGridLines: true, 
        drawMajorGridLines: false, 
        labelPostfix: " cc", 
        labelPrecision: 2,
        growBy: new NumberRange(0, 0.1),
        labelStyle: {fontFamily:"Roboto", fontSize:12}});
        
        const setAutoRangeFlow = (value: boolean) => {
            const maxY = Math.max(...props.chartData.volumeDispensedData.filter(x => !Number.isNaN(x)))
            const maxRangeY = maxY === 0 ? 10 : maxY < 0 ? 0 : maxY
            const minRangeY = Math.min(...props.chartData.volumeDispensedData.filter(x => !Number.isNaN(x))) < 0 ? Math.min(...props.chartData.volumeDispensedData.filter(x => !Number.isNaN(x))) : 0
            
            yAxisFlow.autoRange = value ? EAutoRange.Always : EAutoRange.Never;
    
            if (!value) {
                yAxisFlow.visibleRange = new NumberRange(minRangeY, maxRangeY * 1.20) ;   
            }
        }

        sciChartSurface.yAxes.add(yAxisFlow); 
      // #endregion

      // #region Setup the Chart Modifers
      const legend = new LegendModifier({
        placementDivId: "legend-activity-flow-pressure",
        showCheckboxes: true,
        showSeriesMarkers: true,
        showLegend: true,
         isCheckedChangedCallback: (series: IRenderableSeries, isChecked: boolean) => {
          chartOptions.setVisibleSeriesActivityPressure(series.id, isChecked)  
      }
      });
      sciChartSurface.chartModifiers.add(legend);

      sciChartSurface.chartModifiers.add(
        new CursorModifier({ showAxisLabels: false,  id: "ActivityModifierGroup" }),
        new ZoomPanModifier({xyDirection: EXyDirection.XDirection}),
        new MouseWheelZoomModifier({xyDirection:EXyDirection.XDirection}),
        new ZoomExtentsModifier({xyDirection:EXyDirection.XDirection}),
        new RolloverModifier({tooltipDataTemplate: getTooltipDataTemplate, id: "ActivityRollOver"}),
        new RubberBandXyZoomModifier({ executeOn: EExecuteOn.MouseRightButton }),
      );
      // #endregion

      // #region Create the Data Series and Renderable Series
      const createPressureMinMaxMeanSeries = () => {

          const metadataPressure = props.chartData.pressureEvents?.pressureDataMax.map((maxValue, index) => ({
              label: `${localizationService.toLanguageString('custom.max', enMessages.custom.max)}: ${maxValue} psi,${localizationService.toLanguageString('custom.min', enMessages.custom.min)}: ${props.chartData.pressureEvents?.pressureDataMin[index]} psi,${localizationService.toLanguageString('custom.temperatureInC', enMessages.custom.temperatureInC)}: ${Number(props.chartData.pressureEvents?.temperatureData[index]).toFixed(2)}`
            }));
          
            const pressureMeanSeries = new FastLineRenderableSeries(wasmContext, {  
              stroke: "#3F48CC",  
              id:"PressureMeanId",
              isVisible: chartOptions.visibleSeriesActivityPressure.PressureMeanId});

            pressureMeanSeries.dataSeries = new XyDataSeries(wasmContext, {
              xValues: props.chartData.pressureEvents?.timestamps,
              yValues: props.chartData.pressureEvents?.pressureDataMean,
              dataSeriesName: `${localizationService.toLanguageString('custom.pressureMean', enMessages.custom.pressureMean)} (psi)`,
              metadata: metadataPressure,            
            })
            pressureMeanSeries.rolloverModifierProps.tooltipTextColor = "white"
          
            const pressureMinMaxSeries = new FastBandRenderableSeries(wasmContext, {
              dataSeries:  new XyyDataSeries(wasmContext, { 
                  xValues:  props.chartData.pressureEvents.timestamps, 
                  yValues:  props.chartData.pressureEvents.pressureDataMax, 
                  y1Values: props.chartData.pressureEvents.pressureDataMin , 
                  dataSeriesName:  `${localizationService.toLanguageString('custom.preassureMinMax', enMessages.custom.preassureMinMax)} (psi)`}),
              isVisible: chartOptions.visibleSeriesActivityPressure.PressureMinMaxId,
              stroke: "#C3C3C3",
              strokeY1: "#C3C3C3",
              fill: "#C3C3C3",
              fillY1: "#C3C3C3",
              strokeThickness: 2,
              yAxisId: "DefaultAxisId",
              id: "PressureMinMaxId"
            });
          
            pressureMinMaxSeries.rolloverModifierProps.showRollover = false;
            pressureMeanSeries.rolloverModifierProps.tooltipDataTemplate = getTooltipPressureLegendTemplate;
  
            return {pressureMinMaxSeries, pressureMeanSeries}; 
      }
      
      const pressureSeries = createPressureMinMaxMeanSeries();
      sciChartSurface.renderableSeries.add(pressureSeries.pressureMinMaxSeries);
      sciChartSurface.renderableSeries.add(pressureSeries.pressureMeanSeries);

      const createPressureTemperatureSeries = () => {

          const pressureTemp = new FastLineRenderableSeries(wasmContext, {  
              stroke: "rgba(136,0,21,1)", 
              yAxisId: "Temp",  
              id: "PressureTempId",
              isVisible: chartOptions.visibleSeriesActivityPressure.PressureTempId,
              dataSeries: new XyDataSeries(wasmContext, {
                  xValues: props.chartData.pressureEvents?.timestamps,
                  yValues: props.chartData.pressureEvents?.temperatureData,
                  dataSeriesName: `${localizationService.toLanguageString('custom.pressureTemperature', enMessages.custom.pressureTemperature)} (°C)`,
              })
          });

          pressureTemp.rolloverModifierProps.tooltipColor = "transparent"
          pressureTemp.rolloverModifierProps.tooltipTextColor = "transparent"

          return pressureTemp;
      };
      const pressureTemperature = createPressureTemperatureSeries();
      sciChartSurface.renderableSeries.add(pressureTemperature);

      const createVolumeDispensedSeries = () => {

        const volumeDispensed = new FastImpulseRenderableSeries(wasmContext, {
            stroke: "rgba(50, 53, 67,1)", fill: "rgba(50, 53, 67,1)", 
            strokeThickness:props.chartData.strokeThickness,
            pointMarker: new TrianglePointMarker(wasmContext, {width: 12, height: 12, stroke: "rgba(50, 53, 67,1)", fill: "rgba(50, 53, 67,1)"}),
            paletteProvider: new ColumnPaletteProvider("rgba(50, 53, 67,0.55)"),
            id: "VolumeDispensedId",
            yAxisId: "flowAxis",
            isVisible: chartOptions.visibleSeriesActivityPressure.VolumeDispensedId,     
            dataSeries: new XyDataSeries(wasmContext, {
                xValues: props.chartData.timestamps, 
                yValues: props.chartData.volumeDispensedData, 
                dataSeriesName: `${localizationService.toLanguageString('custom.volumeDispensed', enMessages.custom.volumeDispensed)}` 
                })
        });

        volumeDispensed.rolloverModifierProps.tooltipTextColor = 'White'
        volumeDispensed.rolloverModifierProps.showRollover = true
        volumeDispensed.rolloverModifierProps.markerColor = 'red'

        return volumeDispensed;
      }
      const volumeDispensed = createVolumeDispensedSeries();
      sciChartSurface.renderableSeries.add(volumeDispensed);

      // Create a series that represents the entire filter period so the chart displays the entire range. 
      const filterPeriodSeries = new FastLineRenderableSeries(wasmContext, {
        id: "FilterPeriodSeriesId", 
        isVisible: false,
        yAxisId: "flowAxis",
        dataSeries: new XyDataSeries(wasmContext, {xValues: [startFilterDateUnix, endFilterDateUnix],yValues: [0,0], dataSeriesName: "filterPeriodSeries"})
      }); 
      legend.includeSeries(filterPeriodSeries, false);
      sciChartSurface.renderableSeries.add(filterPeriodSeries);

    // #endregion

  
    // #region Setup Annotations
  // Add 0 line annotation
  const flowZeroLineAnnotation = new HorizontalLineAnnotation({
    labelPlacement: ELabelPlacement.Axis,
    yAxisId:"flowAxis",
    stroke: 'grey',
    strokeThickness: 3,
    y1: 0,
    annotationLayer: EAnnotationLayer.BelowChart
    });
    sciChartSurface.annotations.add(flowZeroLineAnnotation)

    const temperatureZeroLineAnnotation = new HorizontalLineAnnotation({
      id: "TemperatureZeroLineAnnotation",
      labelPlacement: ELabelPlacement.Axis,
      yAxisId:"Temp",
      stroke: 'grey',
      strokeThickness: 3,
      y1: 0,
      isHidden: chartOptions.isSinglePressureChart,
      annotationLayer: EAnnotationLayer.BelowChart
      });
      sciChartSurface.annotations.add(temperatureZeroLineAnnotation)

    const pressureZeroLineAnnotation = new HorizontalLineAnnotation({
      id: "PressureZeroLineAnnotation",
        labelPlacement: ELabelPlacement.Axis,
        yAxisId:"DefaultAxisId",
        stroke: 'grey',
        strokeThickness: 3,
        y1: 0,
        isHidden: chartOptions.isSinglePressureChart,
        annotationLayer: EAnnotationLayer.BelowChart
        });
        sciChartSurface.annotations.add(pressureZeroLineAnnotation);
  
      // Add LastSeen line annotation
    const lastSeenLine = new VerticalLineAnnotation({
      labelPlacement: ELabelPlacement.TopRight,
      stroke: 'grey',
      strokeThickness: 3,
      strokeDashArray: [12,5],
      x1: props.chartData.lastSeen,
      showLabel:true,
      labelValue: `${localizationService.toLanguageString('custom.lastSeen', enMessages.custom.lastSeen)}`,
      axisLabelFill: 'grey',
      annotationLayer: EAnnotationLayer.BelowChart
      });
   // sciChartSurface.annotations.add(lastSeenLine)

    const lastSeenSeries = new FastImpulseRenderableSeries(wasmContext, {
     stroke: "grey", 
     fill: "grey", 
     strokeThickness: props.chartData.strokeThickness,
     id: "lastSeenId",
   });
   
   lastSeenSeries.dataSeries = new XyDataSeries(wasmContext, {
     xValues: [ props.chartData.lastSeen], 
     yValues: [0], 
     dataSeriesName: `${localizationService.toLanguageString('custom.lastSeen', enMessages.custom.lastSeen)}` 
   });
   
   lastSeenSeries.rolloverModifierProps.tooltipTextColor = 'White'
   lastSeenSeries.rolloverModifierProps.showRollover = true

   if(props.chartData.lastSeen >= startFilterDateUnix && props.chartData.lastSeen <= endFilterDateUnix) {
    sciChartSurface.annotations.add(lastSeenLine)
    sciChartSurface.renderableSeries.add(lastSeenSeries);
    legend.includeSeries(lastSeenSeries, false);
  }
   
  // #endregion

    // #region Setup the API's 
    const setIsSingleMode = (value: boolean) => {
        if (value)
        {
            sciChartSurface.layoutManager.leftOuterAxesLayoutStrategy = new LeftAlignedOuterAxisLayoutStrategy();
            sciChartSurface.yAxes.getById("flowAxis").axisAlignment = EAxisAlignment.Right;
            sciChartSurface.yAxes.getById("DefaultAxisId").axisAlignment = EAxisAlignment.Right;
            sciChartSurface.annotations.getById("TemperatureZeroLineAnnotation").isHidden = true;
            sciChartSurface.annotations.getById("PressureZeroLineAnnotation").isHidden = true;
        }
        else
        {
            sciChartSurface.layoutManager.leftOuterAxesLayoutStrategy = new LeftAlignedOuterVerticallyStackedAxisLayoutStrategy();
            sciChartSurface.yAxes.getById("flowAxis").axisAlignment = EAxisAlignment.Left;
            sciChartSurface.yAxes.getById("DefaultAxisId").axisAlignment = EAxisAlignment.Left;
            sciChartSurface.annotations.getById("TemperatureZeroLineAnnotation").isHidden = false;
            sciChartSurface.annotations.getById("PressureZeroLineAnnotation").isHidden = false;
        }
    };

    setIsSingleMode(chartOptions.isSinglePressureChart);

    const setAutoRange = (value: boolean) => {
        UpdateSuspender.using(sciChartSurface, () => {
            setAutoRangeFlow(value);
            setAutoRangeTemperature(value);
            setAutoRangePressure(value);
        })
      }

      setAutoRangeFlow(chartOptions.isAutoRange);
      setAutoRangePressure(chartOptions.isAutoRange);
      setAutoRangeTemperature(chartOptions.isAutoRange);

    // #endregion

        return { sciChartSurface, setAutoRange, setIsSingleMode};
    }

    return {initChart, configAfterInit, setAutoRange, setIsSingleMode};
}