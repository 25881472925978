import { ReactElement, useMemo } from "react";
import { useQuery } from "react-query";
import { UserReadDto } from "../../../Models/Admin/UserReadDto";
import AdministrationUserTable from "../Components/Tables/AdministrationUserTable";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { OrganisationRole } from "../../../Models/Admin/Role";
import administrationQueryService from "../Utilities/AdministrationAPIService";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import adminApiService from "../Utilities/AdministrationAPIService";
import { SiteReadDto } from "../../../Models/Site/SiteReadDto";
import { useToast } from "@chakra-ui/react";
import { UserReadTableDto } from "../../../Models/Admin/UserReadTableDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useWindowSize } from "usehooks-ts";

interface Props {}

export default function SuperAdministrationUsersPage({}: Props): ReactElement {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();
  const { width, height } = useWindowSize()
  
  const usersQuery = useQuery<Array<UserReadDto>>(ReactQueryKeys.AllUsersQuery, async () => {
    return await administrationQueryService.getAllUsers();
  });

  const sitesQuery = useQuery<Array<SiteReadDto>>(ReactQueryKeys.AllSitesQuery, async () => {
    return administrationQueryService.getAllSites();
  });

  const rolesQuery = useQuery<Array<OrganisationRole>>(ReactQueryKeys.AllRolesQuery, async () => {
    return administrationQueryService.getAllRoles();
  });

  const reinviteUser = async (email: string) => {
    try {
      //Determine query based on user
      const result = await administrationQueryService.postUserReinviteSuper(email);

      //On Success, show toast
      toast({
        title: `${localizationService.toLanguageString('custom.success', enMessages.custom.success)}`,
        description: `${localizationService.toLanguageString('custom.userInvitation', enMessages.custom.userInvitation)}`,
        status: "success",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      });
    } catch (e) {
      //On Failure, show error toast
      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        description: `${localizationService.toLanguageString('custom.userInvitationError', enMessages.custom.userInvitationError)}`,
        status: "error",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const deleteUser = async (id: string) => {
    try {
      //Determine query based on user
      const result = await administrationQueryService.postUserDeleteSuper(id);

      //On Success, show toast
      toast({
        title: `${localizationService.toLanguageString('custom.success', enMessages.custom.success)}`,
        description: `${localizationService.toLanguageString('custom.userDeleted', enMessages.custom.userDeleted)}`,
        status: "success",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      });

      await usersQuery.refetch();
    } catch (e: any) {
      //On Failure, show error toast
      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        description: `${localizationService.toLanguageString('custom.userDeletedError', enMessages.custom.userDeletedError)}\n ${
          e?.response?.data?.status === 401}`,
        status: "error",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const inviteUser = async (values: any, setStatus: any) => {
    //Post the form.
    try {
      //Parse details from input into correct format
      const siteRoleMappings = [
        {
          roleId: values.roleId,
          siteId: values.siteId,
        },
      ];

      //Assuming it works, refetch the users
      const result = await administrationQueryService.postUserInviteSuper(values.email, siteRoleMappings);
      usersQuery.refetch();
      //On success, show success toast, then refresh data
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.userInvitation', enMessages.custom.userInvitation)}`,
      });
      return true;
    } catch (e: any) {
      setStatus(e?.response?.data?.errors);

      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        description: `${localizationService.toLanguageString('custom.userInvitationError', enMessages.custom.userInvitationError)}\n`,
        status: "error",
        position: "bottom",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
  };

  const userData = useMemo(() => {
    //Create hashmap of areaId -> name pairs.
    const siteIdToName: { [id: string]: string } = {};
    const roleIdToName: { [id: string]: string } = {};
    sitesQuery.data?.forEach((site) => {
      siteIdToName[site.id] = site.name;
    });
    rolesQuery.data?.forEach((role) => {
      roleIdToName[role.id] = role.name;
    });

    //Map over machines to inject relevant data
    const results = usersQuery.data?.map((user) => {
      //check if user has currentSiteRoleMapping, if not, use first siteRoleMapping
      const siteRoleMapping = user.currentSiteRoleMapping || user.siteRoleMappings[0];
     
      return {
        ...user,
        roleId: siteRoleMapping?.roleId,
        siteId: siteRoleMapping?.siteId,
        siteName: siteIdToName[siteRoleMapping?.siteId],
        roleName: roleIdToName[siteRoleMapping?.roleId],
        siteRoleMappingsWithNames: user.siteRoleMappings?.map((x) => ({
          ...x,
          siteName: siteIdToName[x.siteId],
          roleName: roleIdToName[x.roleId],
        })),
      } as UserReadTableDto;
    });

    return results;
  }, [usersQuery.data, sitesQuery.data, rolesQuery.data]);



  const RenderQuery = () => {
    if (sitesQuery.error || rolesQuery.error || usersQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          {/* TODO: Create Page Title Component - take description and title as props */}
          <div className="m-5">
            <h1 className="gb-h1">{localizationService.toLanguageString('custom.manageUsers', enMessages.custom.manageUsers)}</h1>
            <h2 className="gb-text-normal mb-6">{localizationService.toLanguageString('custom.manageUsersText', enMessages.custom.manageUsersText)}</h2>
            <AdministrationUserTable
              removeUserRole={async (id: string, siteId: string, roleId: string) =>
                await adminApiService.removeUserRoleSuper(id, siteId, roleId)
              }
              addUserRole={async (id: string, siteId: string, roleId: string) => await adminApiService.addUserRoleSuper(id, siteId, roleId)}
              deleteUser={deleteUser}
              inviteUser={inviteUser}
              reinviteUser={reinviteUser}
              sites={sitesQuery.data ?? []}
              roles={rolesQuery.data ?? []}
              users={userData!}
              height={height - 200}
            />
          </div>
        </div>
      );
    }
  };

  return sitesQuery.isLoading || usersQuery.isLoading || rolesQuery.isLoading || sitesQuery.isFetching || usersQuery.isFetching || rolesQuery.isFetching ? <LoadingSpinner /> : <RenderQuery />;
}
