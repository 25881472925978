import { ReactElement, useState } from "react";
import moment from "moment";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { useToast } from "@chakra-ui/react";
import { FrequencyTypes } from "../../../Constants/SchedulerConstants";
import { ReportScheduleReadDto } from "../../../Models/Reporting/ReportScheduleReadDto";
import React from "react";
import {
  FrequencyStringToDisplayName,
} from "../Utilities/ReportUtilities";
import { ReportScheduleCreateDto } from "../../../Models/Reporting/ReportScheduleCreateDto";
import ComplianceReportValidationSchema from "../Utilities/ComplianceReportValidationSchema";
import { Field, FieldProps, Form, Formik } from "formik";
import FieldErrorMessage from "../../Shared/Components/Forms/TableErrorMessage";
import DayPickerWeek from "../../Shared/Components/Forms/DayPickerWeek";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { ReportScheduleUpdateDto } from "../../../Models/Reporting/ReportScheduleUpdateDto";

interface Props {
  onClose: () => void;
  visible: boolean;
  reportSchedule: ReportScheduleReadDto | null;
  createReportSchedule: (
    ReportScheduleCreateDto: ReportScheduleCreateDto
  ) => void;
  updateReportSchedule: (updateReportSchedule: ReportScheduleUpdateDto) => void;
  deleteReportSchedule: (deleteId: any, type: any) => void;
  filters: any;
  type: string;
  reportName: string;
  timezone: string;
}

export default function ReportScheduleModal(props: Props): ReactElement {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const steps = {
    minute: 15,
  };

  const [frequency, setFrequency] = useState<string>(FrequencyTypes.daily);
  const [showSelectionErrorMessage, setShowSelectionErrorMessage] =
    useState<boolean>(false);
  const [daysSelected, setDaysSelected] = useState<Array<number>>([]);
  const [to, setTo] = useState<any>({ to: new Array() });

  React.useEffect(() => {
    if (props.reportSchedule) {
      if (props.reportSchedule.frequency === "Daily") {
        setFrequency("RRule.DAILY");
        setDaysSelected([]);
      } else {
        setFrequency("RRule.WEEKLY");
        setDaysSelected(props.reportSchedule?.recurrence);
      }
    } else {
      setFrequency(FrequencyTypes.daily);
      setDaysSelected([]);
    }
  }, [props.reportSchedule]);

  React.useEffect(() => {
    if (frequency === FrequencyTypes.daily) {
      setShowSelectionErrorMessage(false);
    }

    if (frequency === FrequencyTypes.weekly) {
      if (daysSelected.length === 0) {
        setShowSelectionErrorMessage(true);
      } else {
        setShowSelectionErrorMessage(false);
      }
    }
  }, [frequency, daysSelected]);

  const handleSubmit = async (values: any, setStatus: any) => {
    if (showSelectionErrorMessage) {
      toast({
        status: "error",
        title: `${localizationService.toLanguageString(
          "custom.error",
          enMessages.custom.error
        )}`,
        description: `${localizationService.toLanguageString(
          "custom.mustSelectDay",
          enMessages.custom.mustSelectDay
        )}`,
      });
    } else {
      try {
        //If editing an existing schedule (props.schedule is provided...)
        if (props.reportSchedule) {
          const castValues = {
            id: props.reportSchedule.id,
            period: values.period,
            subject: values.subject,
            frequency: values.frequency,
            timezone: props.timezone,
            startDate: values.startDate,
            hour: values.hour,
            minute: values.minute,
            to: values.to,
            type: props.type,
            status: values.status,
            filterId: props.reportSchedule.filterId,
            filters: '',
            recurrence: daysSelected,
          } as ReportScheduleUpdateDto;
          await props.updateReportSchedule(castValues);
        }
        //If creating an entirely new schedule
        else {
          const castValues = {
            period: values.period,
            subject: values.subject,
            frequency: values.frequency,
            timezone: props.timezone,
            startDate: values.startDate,
            hour: values.hour,
            minute: values.minute,
            to: values.to,
            type: props.type,
            status: values.status,
            filters: '',
            recurrence: daysSelected,
          } as ReportScheduleCreateDto;
          await props.createReportSchedule(castValues);
        }

        toast({
          status: "success",
          description: `'${
            values.subject
          }' ${localizationService.toLanguageString(
            "custom.scheduleSuccessfully",
            enMessages.custom.scheduleSuccessfully
          )} ${
            props.reportSchedule
              ? localizationService.toLanguageString(
                  "custom.updated",
                  enMessages.custom.updated
                )
              : localizationService.toLanguageString(
                  "custom.created",
                  enMessages.custom.created
                )
          }`,
        });
      } catch (e: any) {
        //On failure show error toast, set status messages
        setStatus(e?.response?.data?.errors);

        toast({
          title: `${localizationService.toLanguageString(
            "custom.error",
            enMessages.custom.error
          )}`,
          status: "error",
          description: `${localizationService.toLanguageString(
            "custom.somethingWentWrong",
            enMessages.custom.somethingWentWrong
          )} \n
          ${
            e?.response?.data?.errors
              ? Object.values(e?.response?.data?.errors)[0]
              : ""
          }`,
        });
      }
    }
    props.onClose();
  };

  const initialValueGenerator = (update: boolean) => {
    if (update) {
      return {
        subject: props.reportSchedule?.subject,
        period: props.reportSchedule?.period,
        to: props.reportSchedule?.to,
        frequency: props.reportSchedule?.frequency,
        timezone: props.reportSchedule?.timezone,
        startDate: new Date(props.reportSchedule?.startDate!),
        hour: props.reportSchedule?.hour,
        minute: props.reportSchedule?.minute,
        type: props.reportSchedule?.type,
        status: props.reportSchedule?.status,
        recurrence: props.reportSchedule?.recurrence,
      } as ReportScheduleUpdateDto;
    } else {
      return {
        subject: `[GreaseBoss] ${props.reportName}`,
        period: `${localizationService.toLanguageString(
          "custom.last",
          enMessages.custom.last
        )} 7 ${localizationService.toLanguageString(
          "custom.days",
          enMessages.custom.days
        )}`,
        to: new Array(),
        frequency: "Daily",
        timezone: moment.tz.guess(),
        startDate: tomorrow,
        hour: 8,
        minute: 0,
        type: props.type,
        status: "",
        recurrance: new Array(),
      } as unknown as ReportScheduleCreateDto;
    }
  };

  const RenderRecurrence = () => {
    if (frequency === FrequencyTypes.weekly) {
      return (
        <div className="p-2">
          <label htmlFor="weeklyRecurrence">
            {localizationService.toLanguageString(
              "custom.weeklyRecurrenceInterval",
              enMessages.custom.weeklyRecurrenceInterval
            )}
          </label>
          <DayPickerWeek
            daysSelected={daysSelected}
            setDaysSelected={setDaysSelected}
          />
          {showSelectionErrorMessage && (
            <div className="text-xs text-red-600">
              *{" "}
              {localizationService.toLanguageString(
                "custom.mustSelectWeek",
                enMessages.custom.mustSelectWeek
              )}
            </div>
          )}
          <FieldErrorMessage name="recurrence" />
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {props.visible && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.scheduleEmail",
            enMessages.custom.scheduleEmail
          )}
          onClose={props.onClose}
          className="schedule-emails"
        >
          <Formik
            onSubmit={async (values, { setStatus }) => {
              await handleSubmit(values, setStatus);
            }}
            validationSchema={ComplianceReportValidationSchema}
            initialValues={initialValueGenerator(
              props.reportSchedule ? true : false
            )}
          >
            {({ submitForm, values, initialValues, setFieldValue }) => (
              <>
                <Form
                  style={{
                    width: "600px",
                  }}
                >
                  <div className="flex justify-between mx-3">
                    <div className="my-3">
                      {localizationService.toLanguageString(
                        "custom.emailScheduleText",
                        enMessages.custom.emailScheduleText
                      )}
                    </div>
                  </div>
                  <div className="p-2 flex items-center">
                    <label htmlFor="name" className="w-24">
                      {localizationService.toLanguageString(
                        "custom.from",
                        enMessages.custom.from
                      )}
                    </label>
                    <Field
                      className="k-input k-input-md k-rounded-md k-input-solid h-8"
                      value={"noreply@greasecloud.io"}
                      disabled={true}
                    />
                  </div>

                  <div className="p-2">
                    <div className="flex items-center">
                      <label htmlFor="to" className="w-20">
                        {localizationService.toLanguageString(
                          "custom.to",
                          enMessages.custom.to
                        )}
                      </label>
                      <Field
                        name="to"
                        type="email"
                        render={({ form }: FieldProps) => (
                          <>
                            <ReactMultiEmail
                              placeholder={"Email"}
                              emails={props.reportSchedule?.to}
                              onChange={(_to: string[]) => {
                                setTo(_to);
                                form.setFieldValue("to", _to);
                              }}
                              validateEmail={(email) => {
                                return isEmail(email);
                              }}
                              getLabel={(email, index, removeEmail) => {
                                return (
                                  <div data-tag key={index}>
                                    {email}
                                    <span
                                      data-tag-handle
                                      onClick={() => removeEmail(index)}
                                    >
                                      ×
                                    </span>
                                  </div>
                                );
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div className="ml-20">
                      <FieldErrorMessage name="to" />
                    </div>
                  </div>

                  <div className="p-2 ">
                    <div className="flex items-center">
                      <label htmlFor="subject" className="w-24">
                        {localizationService.toLanguageString(
                          "custom.subject",
                          enMessages.custom.subject
                        )}
                      </label>
                      <Field
                        name="subject"
                        className="k-input k-input-md k-rounded-md k-input-solid h-8"
                        type="text"
                        defaultValue={initialValues.subject}
                      />
                    </div>
                    <div className="ml-20">
                      <FieldErrorMessage name="subject" />
                    </div>
                  </div>

                  <div className="p-2 flex items-center">
                    <label htmlFor="period" className="w-24">
                      {localizationService.toLanguageString(
                        "custom.period",
                        enMessages.custom.period
                      )}
                    </label>
                    <Field
                      name={"period"}
                      render={({ field, form, meta }: FieldProps) => (
                        <DropDownList
                          style={{ height: "30px" }}
                          defaultValue={initialValues.period}
                          onChange={(e: any) => {
                            form.setFieldValue("period", e.value);
                          }}
                          data={[
                            `${localizationService.toLanguageString(
                              "custom.last",
                              enMessages.custom.last
                            )} 7 ${localizationService.toLanguageString(
                              "custom.days",
                              enMessages.custom.days
                            )}`,
                            `${localizationService.toLanguageString(
                              "custom.last",
                              enMessages.custom.last
                            )} 14 ${localizationService.toLanguageString(
                              "custom.days",
                              enMessages.custom.days
                            )}`,
                            `${localizationService.toLanguageString(
                              "custom.last",
                              enMessages.custom.last
                            )} 28 ${localizationService.toLanguageString(
                              "custom.days",
                              enMessages.custom.days
                            )}`,
                            `${localizationService.toLanguageString(
                              "custom.last",
                              enMessages.custom.last
                            )} 30 ${localizationService.toLanguageString(
                              "custom.days",
                              enMessages.custom.days
                            )}`,
                            `${localizationService.toLanguageString(
                              "custom.today",
                              enMessages.custom.today
                            )}`,
                            `${localizationService.toLanguageString(
                              "custom.yesterday",
                              enMessages.custom.yesterday
                            )}`,
                          ]}
                        />
                      )}
                    />
                  </div>

                  <div className="p-2 flex items-center">
                    <label htmlFor="frequency" className="w-24">
                      {localizationService.toLanguageString(
                        "custom.frequency",
                        enMessages.custom.frequency
                      )}
                    </label>
                    <Field
                      name="frequency"
                      render={({ field, form, meta }: FieldProps) => (
                        <DropDownList
                          style={{ height: "30px" }}
                          textField="displayName"
                          defaultValue={{
                            value: frequency,
                            displayName:
                              FrequencyStringToDisplayName(frequency),
                          }}
                          onChange={(e: any) => {
                            setFrequency(e.target.value.value);
                            form.setFieldValue(
                              "frequency",
                              e.value.displayName
                            );
                          }}
                          data={[
                            {
                              displayName: `${localizationService.toLanguageString(
                                "custom.daily",
                                enMessages.custom.daily
                              )}`,
                              value: "RRule.DAILY",
                            },
                            {
                              displayName: `${localizationService.toLanguageString(
                                "custom.weekly",
                                enMessages.custom.weekly
                              )}`,
                              value: "RRule.WEEKLY",
                            },
                          ]}
                        />
                      )}
                    ></Field>
                    <FieldErrorMessage name="frequency" />
                  </div>

                  <RenderRecurrence />

                  <div className="p-2 flex items-center">
                    <label htmlFor="timezone" className="w-24">
                      {localizationService.toLanguageString(
                        "custom.timezone",
                        enMessages.custom.timezone
                      )}
                    </label>
                    <Field
                      className="k-input k-input-md k-rounded-md k-input-solid h-8"
                      value={props.timezone}
                      disabled={true}
                    />
                  </div>

                  <div className="p-2 flex items-center">
                    <label htmlFor="startDate" className="w-24">
                      {localizationService.toLanguageString(
                        "custom.startDate",
                        enMessages.custom.startDate
                      )}
                    </label>

                    <DatePicker
                      onChange={(date) => {
                        setFieldValue("startDate", date.value);
                      }}
                      defaultValue={initialValues.startDate}
                    />
                  </div>

                  <div className="p-2 flex items-center">
                    <label htmlFor="startTime" className="w-24">
                      {localizationService.toLanguageString(
                        "custom.time",
                        enMessages.custom.time
                      )}
                    </label>

                    <TimePicker
                      format="HH:mm"
                      onChange={(time) => {
                        setFieldValue("hour", time.value?.getHours());
                        setFieldValue("minute", time.value?.getMinutes());
                      }}
                      defaultValue={
                        new Date(
                          initialValues.startDate.setHours(
                            initialValues.hour,
                            initialValues.minute,
                            0
                          )
                        )
                      }
                      steps={steps}
                    />
                  </div>
                </Form>

                <DialogActionsBar>
                  <Button
                    themeColor={"success"}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    {localizationService.toLanguageString(
                      "custom.save",
                      enMessages.custom.save
                    )}
                  </Button>

                  <Button
                    onClick={() =>
                      props.deleteReportSchedule(
                        props.reportSchedule?.id,
                        props.reportSchedule?.type
                      )
                    }
                  >
                    {localizationService.toLanguageString(
                      "custom.delete",
                      enMessages.custom.delete
                    )}
                  </Button>
                  <Button onClick={props.onClose}>
                    {localizationService.toLanguageString(
                      "custom.cancel",
                      enMessages.custom.cancel
                    )}
                  </Button>
                </DialogActionsBar>
              </>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  );
}
