import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { DateRangePicker, DateRangePickerChangeEvent } from "@progress/kendo-react-dateinputs/dist/npm/daterangepicker/DateRangePicker";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer"
import { useEffect, useMemo, useRef, useState } from "react";
import CustomEndDateInput from "./CustomEndDateInput";
import CustomStartDateInput from "./CustomStartDateInput";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import useIdentityStore from "../../../Zustand/identityStore";
import moment from "moment";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import areasApiService from "../../Area/Utilities/AreasApiService";
import { PointReadDto } from "../../../Models/Point/PointReadDto";
import pointsApiService from "../../Points/Utilities/PointsApiService";
import { MachineReadDto } from "../../../Models/Machine/MachinesReadDto";
import machinesApiService from "../../Machines/Utilities/MachinesApiService";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { Button } from "@progress/kendo-react-buttons";
import { StringResources }from "../../../messages/stringResources"
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import { Splitter } from "@progress/kendo-react-layout";
import { ReportScheduleReadDto } from "../../../Models/Reporting/ReportScheduleReadDto";
import reportScheduleApiService from "../Utilities/ReportApiService";
import { ReportScheduleCreateDto } from "../../../Models/Reporting/ReportScheduleCreateDto";
import { useToast } from "@chakra-ui/react";
import ReportScheduleModal from "./ReportModal";
import ReportFilter from "./ReportFilter";
import { ReportScheduleUpdateDto } from "../../../Models/Reporting/ReportScheduleUpdateDto";
import { ReportScheduleGetDto } from "../../../Models/Reporting/ReportScheduleGetDto";
import { ScheduleReadDto } from "../../../Models/Schedule/ScheduleReadDto";
import { TaskReadDto } from "../../../Models/Task/TaskReadDto";
import tasksApiService from "../../Tasks/Utilities/TasksApiService";
import { distinct } from '@progress/kendo-data-query';
import schedulesApiService from "../../Schedules/Utilities/ScheduleApiService";
import ReportSendEmailModal from "./SendEmailModal";

interface Props {}

export default function ComplianceReport(props: Props, ) {

  const localizationService = useLocalization();
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const reportScheduleQuery = useQuery<ReportScheduleReadDto>(ReactQueryKeys.AllReportScheduleQuery, async () => {
    return reportScheduleApiService.getAllReportSchedule("ComplianceReport");
  });

  const createReportSchedule = async (ReportScheduleCreateDto: ReportScheduleCreateDto) => {
    const result = await reportScheduleApiService.createReportSchedule(ReportScheduleCreateDto);
    client.refetchQueries(ReactQueryKeys.AllReportScheduleQuery);
  };

  const deleteReportSchedule = async (id: any, type:any) => {
    try {
      let response = await reportScheduleApiService.deleteReportSchedule(id, type);

      //If delete is successful, refetch the updated list of devices and show success message
      client.refetchQueries(ReactQueryKeys.AllReportScheduleQuery);

      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.schedulesDeleted', enMessages.custom.schedulesDeleted)}`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `${localizationService.toLanguageString('custom.schedulesDeletedError', enMessages.custom.schedulesDeletedError)}`,
      });
    }
    toggleDialogSchedule()
  };

  const updateComplianceReports = async (updateComplianceReport: ReportScheduleUpdateDto) => {
    let response = await reportScheduleApiService.updateReportSchedule(updateComplianceReport);
    //If update is successful, refetch the updated list of areas and show success message
    client.refetchQueries(ReactQueryKeys.AllReportScheduleQuery);
  }
  
  const viewerRef : any = useRef(null);
  const [token, setToken] = useState("");
  const [language, setLanguage] = useState(navigator.language);

  const serviceUrl = process.env.REACT_APP_TELERIK_REPORTING_URL + "/api/reports";
  const reportUrl = process.env.REACT_APP_REPORT_SERVICE_URL + ApiRoutes.ReportCompliance;

  const userProfile = useIdentityStore((store) => store.user?.profile);
  const defaultStartDate = moment().subtract(7, 'days').startOf('isoWeek');
  const defaultDateRange = {start: new Date(defaultStartDate.toISOString()), end: new Date(defaultStartDate.endOf('isoWeek').toISOString())};
  const [dateRange, setDateRange] = useState<SelectionRange>(defaultDateRange);

  const [success, setSuccess] = React.useState(false);
  const [panes, setPanes] = React.useState<Array<any>>([{}]);
  const [disabled, setDisabled] =  React.useState(false);
  const [disableReport, setDisableReport] = React.useState(false);   

  const [visibleSchedule, setVisibleSchedule] = React.useState<boolean>(false);
  const [visibleSendEmail, setVisibleSendEmail] = React.useState<boolean>(false);

  const toggleDialogSchedule = () => {
    setVisibleSchedule(!visibleSchedule);
  };
  const toggleDialogSendEmail = () => {
    setVisibleSendEmail(!visibleSendEmail);
  };

  const renderingEnd = () => {
    setSuccess(false);
    setDisabled(false)
  }

  const ready = () => {
    setSuccess(true);
    
  }

  const schedulesQuery = useQuery<Array<ScheduleReadDto>>(ReactQueryKeys.AllSchedulesQuery, async () => {
    return schedulesApiService.getAllSchedules();
  });

  const areasQuery = useQuery<Array<AreaReadDto>>(ReactQueryKeys.AllAreasQuery, async () => {
    return areasApiService.getAllAreas();
  });

  const machinesQuery = useQuery<Array<MachineReadDto>>(ReactQueryKeys.AllMachinesQuery, async () => {
    return machinesApiService.getAllMachines();
  });

  const pointsQuery = useQuery<Array<PointReadDto>>(ReactQueryKeys.AllPointsQuery, async () => {
    return pointsApiService.getAllPoints();
  });

  const tasksQuery = useQuery<Array<TaskReadDto>>(ReactQueryKeys.AllTasksQuery, async () => {
    return tasksApiService.getAllTasks();
  });

  const getToken = async () => {
    try {
      const user = await authorizeService.getUser();
      setToken(user.access_token);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() =>  {
    getToken();
  }, [])

  useEffect(() =>  {
  }, [dateRange, token])

  const handleChange = (event: DateRangePickerChangeEvent) => {
    setDisabled(false)
   if (event.value.end !== null)
   {
    setDateRange(event.value);
   }
  };

  //This function finds the most frequent timezone schedule
  const scheduleTimezone = () => {
    if(schedulesQuery.data !== undefined) {
      const timezone = schedulesQuery.data.map(item => item.timezone)

      // Check if we have at least 1 schedule
      if (timezone.length > 0)
      {
        const hashmap = timezone.reduce( (acc, val) => {
          acc[val] = (acc[val] || 0 ) + 1
          return acc
        },{})
        return Object.keys(hashmap).reduce((a, b) => hashmap[a] > hashmap[b] ? a : b)
      }
    }
    
    return "";
  }

  const dateInputSettings = {
    label: "",
    format: "d MMM, y"
  };

  const getFromDateInUtc = () => {
    const fromDate = moment(dateRange?.start).format("YYYY-MM-DDT00:00:00");
    return moment.tz(fromDate, scheduleTimezone()).utc().toISOString();
  }

  const timezoneOffset = () => {
    const fromDate = moment(dateRange?.start).format("YYYY-MM-DDT00:00:00");
    return moment.tz(fromDate, scheduleTimezone()).utcOffset();
  }

  const getToDateInUtc = () => {
    const toDate = moment(dateRange?.end).format("YYYY-MM-DDT23:59:59");
    return moment.tz(toDate, scheduleTimezone()).utc().toISOString();
  }

  const getReportDate = () => {
    return moment(dateRange?.start).format("DD MMM, YYYY") + " - " + moment(dateRange?.end).format("DD MMM, YYYY")
  }

  const [allLocations, setAllLocations] = React.useState<any[]>([]);
  const [selectedLocations, setSelectedLocations] = React.useState<any[]>([]);
  const [allAssets, setAllAssets] = React.useState<any[]>([]);
  const [selectedAssets, setSelectedAssets] = React.useState<any[]>([]);
  const [allPoints, setAllPoints] = React.useState<any[]>([]);
  const [selectedPoints, setSelectedPoints] = React.useState<any[]>([]);
  const [selectedPointsFilter, setSelectedPointsFilter] = React.useState<any[]>([]);
  const [allSchedules, setAllSchedules] = React.useState<any[]>([]);
  const [selectedSchedules, setSelectedSchedules] = React.useState<any[]>([]);

  const locations = [
    {
      name: `${localizationService.toLanguageString('custom.selectAll', enMessages.custom.selectAll)}`,
      id: 1,
      items: new Array
    }
  ];

  const assets = [
    {
      name: `${localizationService.toLanguageString('custom.selectAll', enMessages.custom.selectAll)}`,
      id: 2,
      items: new Array
    },
  ];

  const points = [
    {
      name: `${localizationService.toLanguageString('custom.selectAll', enMessages.custom.selectAll)}`,
      id: 3,
      items: new Array
    },
  ];

  const schedules = [
    {
      name: `${localizationService.toLanguageString('custom.selectAll', enMessages.custom.selectAll)}`,
      id: 4,
      items: new Array
    },
  ];

  const pointDataSchedule : any = useMemo(() => {
    const scheduleIdToName: { [id: string]: string } = {};
    const pointIdToTask: { [id: string]: TaskReadDto } = {};
    schedulesQuery.data?.forEach((schedule) => {
      scheduleIdToName[schedule.id] = schedule.name;
    });
    tasksQuery.data?.forEach((task) => {
      pointIdToTask[task.point.id] = task;
    });


    const pointsInitial = pointsQuery.data?.map((point) => {
      return {
        ...point,
        scheduleId: pointIdToTask[point.id]?.scheduleId === null || pointIdToTask[point.id]?.scheduleId === undefined ? "" : pointIdToTask[point.id]?.scheduleId,
        scheduleName: scheduleIdToName[pointIdToTask[point.id]?.scheduleId] === undefined ? "No Schedule" : scheduleIdToName[pointIdToTask[point.id]?.scheduleId],
      } 
    });

    points[0].items = pointsInitial !== undefined ? pointsInitial.slice().filter(point => point.scheduleId !== '') : new Array
    const selectAllPoints = points[0].items.slice()
    selectAllPoints.unshift({name: 'Select All', id: 3})

    setAllPoints(points)
    setSelectedPoints(selectAllPoints)
    setSelectedPointsFilter(selectAllPoints)

    return pointsInitial
  }, [
    schedulesQuery.data,
    tasksQuery.data,
    pointsQuery.data,
  ]);

  useEffect(() => {
    const resultDistinctAsset : any = pointDataSchedule.filter(point => point.scheduleId !== '') !== undefined ? distinct(pointDataSchedule.filter(point => point.scheduleId !== ''), "areaId") : new Array
    const locationDataSchedule = areasQuery.data?.filter(point => resultDistinctAsset.some(item => item.areaId === point.id))
    locations[0].items = locationDataSchedule !== undefined ? locationDataSchedule.slice() : new Array
    const selectAllLocations = locations[0].items.slice()
    selectAllLocations.unshift({name: 'Select All', id: 1})

    setAllLocations(locations)
    setSelectedLocations(selectAllLocations)
    
  },[areasQuery.data, pointDataSchedule, tasksQuery.data, schedulesQuery.data])

  useEffect(() => {
    const resultDistinctAsset : any = pointDataSchedule.filter(point => point.scheduleId !== '') !== undefined ? distinct(pointDataSchedule.filter(point => point.scheduleId !== ''), "machineId") : new Array
    const assetDataSchedule = machinesQuery.data?.filter(point => resultDistinctAsset.some(item => item.machineId === point.id))
    assets[0].items = assetDataSchedule !== undefined ? assetDataSchedule.slice() : new Array
    const selectAllAssets = assets[0].items.slice()
    selectAllAssets.unshift({name: 'Select All', id: 2})

    setAllAssets(assets)
    setSelectedAssets(selectAllAssets)
    
  },[machinesQuery.data, pointDataSchedule, tasksQuery.data, schedulesQuery.data])

  useEffect(() => {
    const filteredSchedules = schedulesQuery.data?.filter(schedule => pointDataSchedule?.some(item => item.scheduleId === schedule.id))
    schedules[0].items = filteredSchedules !== undefined ? filteredSchedules.slice() : new Array
    const selectAllSchedules = schedules[0].items.slice()
    selectAllSchedules.unshift({name: 'Select All', id: 4})

    setAllSchedules(schedules)
    setSelectedSchedules(selectAllSchedules)

  },[schedulesQuery.data, pointDataSchedule])

  const onChangeLocation = (data: any) => {
    const filteredAssets = allAssets[0].items.filter(asset => data.some(item => item.id === asset.areaId))
    const filteredPoints = allPoints[0].items.filter(point => data.some(item => item.id === point.areaId))
    const filteredSchedulesbyPoints = filteredPoints.filter(schedule => allSchedules[0].items.some(item => item.id === schedule.scheduleId))
    const resultDistinct : {scheduleId: string}[] = distinct(filteredSchedulesbyPoints, "scheduleId")
    const filteredSchedules = allSchedules[0].items.filter(schedule => resultDistinct.some(item => item.scheduleId === schedule.id))
    
    if(data.length === 0) {
      setSelectedAssets(filteredAssets);
      setSelectedPoints(filteredPoints);
      setSelectedSchedules(filteredSchedules);
      setSelectedPointsFilter(filteredPoints)

    } else {      
      filteredAssets.unshift({name: 'Select All', id: 2})
      filteredPoints.unshift({name: 'Select All', id: 3})
      filteredSchedules.unshift({name: 'Select All', id: 4})

      setSelectedAssets(filteredAssets);
      setSelectedPoints(filteredPoints);
      setSelectedSchedules(filteredSchedules);
      setSelectedPointsFilter(filteredPoints)
    }
  }

  const onChangeAsset = (data: any) => {
    const filteredLocations = allLocations[0].items.filter((area => data.some(item=> item.areaId === area.id)))
    const filteredPoints = allPoints[0].items.filter(point => data.some(item => item.id === point.machineId))
    
    const filteredSchedulesbyPoints = filteredPoints.filter(schedule => allSchedules[0].items.some(item => item.id === schedule.scheduleId))
    const resultDistinct : {scheduleId: string}[] = distinct(filteredSchedulesbyPoints, "scheduleId")
    const filteredSchedules = allSchedules[0].items.filter(schedule => resultDistinct.some(item => item.scheduleId === schedule.id))
    
    if(data.length === 0) {
      setSelectedLocations(filteredLocations);
      setSelectedPoints(filteredPoints);
      setSelectedSchedules(filteredSchedules);
      setSelectedPointsFilter(filteredPoints)
    } else {
      filteredLocations.unshift({name: 'Select All', id: 1})
      filteredPoints.unshift({name: 'Select All', id: 3})
      filteredSchedules.unshift({name: 'Select All', id: 4})

      setSelectedLocations(filteredLocations);
      setSelectedPoints(filteredPoints);
      setSelectedSchedules(filteredSchedules);
      setSelectedPointsFilter(filteredPoints)
    }
  };

  const onChangePoint = (data: any) => {
    const filteredAssets = allAssets[0].items.filter(asset => data.some(item => item.machineId === asset.id))
    const filteredLocations = allLocations[0].items.filter((area => data.some(item=> item.areaId === area.id)))
    const filteredSchedules = allSchedules[0].items.filter(schedule => data.some(item => item.scheduleId === schedule.id))
    const filteredPoints= allPoints[0].items.filter(point => data.some(item => item.id === point.id))

    if(data.length === 0) {
      setSelectedAssets(filteredAssets);
      setSelectedLocations(filteredLocations);
      setSelectedSchedules(filteredSchedules);
      setSelectedPointsFilter(filteredPoints)
    } else {
      filteredAssets.unshift({name: 'Select All', id: 2})
      filteredLocations.unshift({name: 'Select All', id: 1})
      filteredSchedules.unshift({name: 'Select All', id: 4})

      setSelectedAssets(filteredAssets);
      setSelectedLocations(filteredLocations);
      setSelectedSchedules(filteredSchedules);
      setSelectedPointsFilter(filteredPoints)
    }
  }

  const onChangeSchedule = (data: any) => {
    const filteredPoints = allPoints[0].items.filter(point => data.some(item => item.id === point.scheduleId))
    const resultDistinctLocation : {areaId: string}[] = distinct(filteredPoints, "areaId")
    const filteredLocations = allLocations[0].items.filter(schedule => resultDistinctLocation.some(item => item.areaId === schedule.id))
    const resultDistinctAsset : {machineId: string}[] = distinct(filteredPoints, "machineId")
    const filteredAssets = allAssets[0].items.filter(schedule => resultDistinctAsset.some(item => item.machineId === schedule.id))

    if(data.length === 0) {
      setSelectedAssets(filteredAssets);
      setSelectedLocations(filteredLocations);
      setSelectedPoints(filteredPoints);
      setSelectedPointsFilter(filteredPoints)
    } else {
      filteredAssets.unshift({name: 'Select All', id: 2})
      filteredLocations.unshift({name: 'Select All', id: 1})
      filteredPoints.unshift({name: 'Select All', id: 3})

      setSelectedAssets(filteredAssets);
      setSelectedLocations(filteredLocations);
      setSelectedPoints(filteredPoints);
      setSelectedPointsFilter(filteredPoints)
    }
  }

  const [filters, setFilters] = React.useState<any[]>([]);

  useEffect(() => {
    if(allPoints.length !== 0 && 
       selectedPoints.length !== 0 && 
       selectedAssets.length !== 0 && 
       selectedLocations.length !== 0) {
      
      const machineIdtoName: { [id: string]: string } = {};
      selectedAssets
        .filter(machine => machine.name !== 'Select All')
        .forEach((machine) => {
          machineIdtoName[machine.id] = machine.name;
        });

      const areaIdtoName: { [id: string]: string } = {};
      selectedLocations
        .filter(area => area.name !== 'Select All')
        .forEach((area) => {
          areaIdtoName[area.id] = area.name;
        });

      if (Object.keys(machineIdtoName).length > 0 && Object.keys(areaIdtoName).length > 0) {
        const totalPoints: { [id: string]: number } = {};
        allPoints[0].items.forEach((point) => {
          if(totalPoints[point.machineId]) {
            totalPoints[point.machineId] = totalPoints[point.machineId] + 1
          } else {
            totalPoints[point.machineId] = 1
          }
        });

        const filteredPoints = selectedPointsFilter.slice(1);
        const filter = filteredPoints.map((point) => ({
          machineName: machineIdtoName[point.machineId],
          machineId: point.machineId,
          locationName: areaIdtoName[point.areaId],
          locationId: point.areaId,
          pointName: point.name,
          pointId: point.id,
          scheduleId: point.scheduleId,
          scheduleName: point.scheduleName,
          totalPoints: totalPoints[point.machineId]
        }));

        setFilters(filter);
      }
    }
  }, [
    selectedPointsFilter?.length,
    allPoints?.length,
    selectedAssets?.length,
    selectedLocations?.length
  ]);

  const resetFilter = () => {
    const selectAllLocations = allLocations[0].items.slice()
    selectAllLocations.unshift({name: 'Select All', id: 1})
    setSelectedLocations(selectAllLocations)

    const selectAllAssets = allAssets[0].items.slice()
    selectAllAssets.unshift({name: 'Select All', id: 2})
    setSelectedAssets(selectAllAssets)

    const selectAllPoints = allPoints[0].items.slice()
    selectAllPoints.unshift({name: 'Select All', id: 3})
    setSelectedPoints(selectAllPoints)
    setDisabled(false)

    const selectAllSchedules = allSchedules[0].items.slice()
    selectAllSchedules.unshift({name: 'Select All', id: 4})
    setSelectedSchedules(selectAllSchedules)
    setDisabled(false)
  }

  const handleApply = () => {
    setDisableReport(true)
    setSuccess(true);
    setDisabled(true)

    if (viewerRef) {
      const {viewerObject} = viewerRef.current;
      const reportDate = getReportDate();
      var fromDateInUtc = getFromDateInUtc();
      var toDateInUtc = getToDateInUtc();
      var timezone = scheduleTimezone()
      var timezoneOffsetInMinutes = timezoneOffset()
      
      viewerObject.reportSource({report: "ComplianceReport.trdp", parameters: ({"Url":reportUrl, "Token": token, "SiteName": userProfile?.current_site_name, "ReportDate":reportDate, "FromDate": fromDateInUtc, "ToDate": toDateInUtc, "Filters": JSON.stringify(filters), "Timezone": timezone, "TimezoneOffsetInMinutes": timezoneOffsetInMinutes})})
      switch (language) {
        case "pt-BR":
          viewerObject.stringResources = Object.assign(viewerObject.stringResources, StringResources.portuguese);
          break;
        default:
          viewerObject.stringResources = Object.assign(viewerObject.stringResources, StringResources.english);
          break;
      }
  }}

//       Loading renderer function to ensure all Queries all loaded.
  if (machinesQuery.isLoading || areasQuery.isLoading || pointsQuery.isLoading || schedulesQuery.isLoading || reportScheduleQuery.isLoading || tasksQuery.isLoading || machinesQuery.isFetching || areasQuery.isFetching || pointsQuery.isFetching || schedulesQuery.isFetching || reportScheduleQuery.isFetching || tasksQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
    if (machinesQuery.error || areasQuery.error || pointsQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div >
          <div className="flex items-cente justify-between">
            <h2 className="m-5 gb-h2">{localizationService.toLanguageString('custom.complianceReport', enMessages.custom.complianceReport)}</h2>
              <div className="flex items-center justify-items-end">
                {disableReport && (
                  <Button
                  icon="graph"
                  className="h-8 mr-2"
                  themeColor={"primary"}
                  disabled={disabled}
                  onClick={handleApply}
                >{localizationService.toLanguageString('custom.generateReport', enMessages.custom.generateReport)}
                </Button>
                )}
                <Button
                  icon="filter-clear"
                  className="h-8 mr-2"
                  themeColor={"primary"}
                  disabled={disabled}
                  onClick={resetFilter}
                >{localizationService.toLanguageString('custom.clearFilters', enMessages.custom.clearFilters)}
                </Button>
                {userProfile?.role === 'SuperAdmin' &&(
                  <Button
                  className="h-8 mr-2"
                  icon="calendar"
                  onClick={toggleDialogSchedule}
                  themeColor={"primary"}
                  disabled={disabled}
                >
                  {localizationService.toLanguageString('custom.scheduleEmail', enMessages.custom.scheduleEmail)}
                </Button>
                )} 
                 {userProfile?.role === 'SuperAdmin' &&(
                  <Button
                  className="h-8 mr-8"
                  icon="email"
                  onClick={toggleDialogSendEmail}
                  themeColor={"primary"}
                  disabled={disabled}
                >
                 {localizationService.toLanguageString('custom.sendEmail', enMessages.custom.sendEmail)}
                </Button>
                )} 
                
              </div>
            </div>
     
            <div className="flex m-5">
              <div>
                <div className="mr-8 border-r-2 pr-8">
                  <DateRangePicker
                  startDateInput={CustomStartDateInput}
                  endDateInput={CustomEndDateInput}
                  startDateInputSettings={dateInputSettings}
                  endDateInputSettings={dateInputSettings}
                  defaultValue={defaultDateRange}
                  onChange={handleChange}
                  disabled={disabled}
                  />
                </div>
                <div className="m-1 text-sm">
                  ({scheduleTimezone()})
                </div>
              </div>
              <div className="w-full mr-2.5">
                <label >{localizationService.toLanguageString('custom.locations', enMessages.custom.locations)}</label>
                <ReportFilter
                  data={selectedLocations}
                  disabled={disabled}
                  onChange={onChangeLocation}
                  tag={localizationService.toLanguageString('custom.locations', enMessages.custom.locations)}
                  initialData={locations}
                  textField={'name'}
                />
              </div>
              
              <div className="w-full mr-2.5">
                <label >{localizationService.toLanguageString('custom.assets', enMessages.custom.assets)}</label>
                <ReportFilter
                  data={selectedAssets}
                  disabled={disabled}
                  onChange={onChangeAsset}
                  tag={localizationService.toLanguageString('custom.assets', enMessages.custom.assets)}
                  initialData={assets}
                  textField={'name'}
                />

              </div>
               <div className="w-full mr-2.5">
                <label >{localizationService.toLanguageString('custom.points', enMessages.custom.points)}</label>
                 <ReportFilter
                  data={selectedPoints}
                  disabled={disabled}
                  onChange={onChangePoint}
                  tag={localizationService.toLanguageString('custom.points', enMessages.custom.points)}
                  initialData={points}
                  textField={'name'}
                />
              </div>
              <div className="w-full mr-2.5">
                <label >{localizationService.toLanguageString('custom.schedules', enMessages.custom.schedules)}</label>
                 <ReportFilter
                  data={selectedSchedules}
                  disabled={disabled}
                  onChange={onChangeSchedule}
                  tag={localizationService.toLanguageString('custom.schedules', enMessages.custom.schedules)}
                  initialData={schedules}
                  textField={'name'}
                />
              </div>
            </div> 
            
             <div className={"appContainer"}>
                 <div style={{position:'relative', height:'100vh'}}>

                  {!disableReport && (
                    <Splitter style={{ height:'100%', zIndex:1, border:'none', }} panes={panes}>
                      <div className="gb-text-normal  text-lg text-center"
                        style={{width: '400px', borderRadius: '3px', padding: '40px 25px',margin: '30px auto'}}>
                        <div className="text-center mb-5">
                          <span className="k-icon k-i-graph k-icon-70"></span>
                        </div>
                        <div>
                        <span>{localizationService.toLanguageString('custom.setFiltersText', enMessages.custom.setFiltersText)}</span>
                        <ul className="mt-5">
                          <li>
                          <Button
                            icon="graph"
                            className="h-8"
                            themeColor={"primary"}
                            onClick={handleApply}
                          >{localizationService.toLanguageString('custom.generateReport', enMessages.custom.generateReport)}
                          </Button>
                          </li>
                        </ul>
                        </div>
                      </div> 
                    </Splitter>)
                  }

                  {disableReport && success && (
                    <div >
                      <Splitter style={{ height:'100%', zIndex:1 }} panes={panes}>
                        <LoadingSpinner />
                      </Splitter>
                    </div>)
                  }
                  
                  {token != "" && filters.length > 0 ? 
                  <TelerikReportViewer
                    ref={viewerRef}
                    serviceUrl={serviceUrl}
                    renderingEnd={renderingEnd}
                    ready={ready}
                    // reportSource={{
                    // report: 'ComplianceReport.trdp',
                    // parameters: {"Url":reportUrl,"Token": token, 
                    //              "SiteName": userProfile?.current_site_name,
                    //              "ReportDate": getReportDate(), 
                    //              "FromDate": getFromDateInUtc(), 
                    //              "ToDate": getToDateInUtc(),
                    //              "Filters": JSON.stringify(filters),
                    //              }
                    // }}
                    viewerContainerStyle = {{
                      position: 'absolute',
                      left: '10px',
                      right: '20px',
                      top: '10px',
                      bottom: '10px',
                      overflow: 'hidden',
                      clear: 'both',
                      fontFamily: 'Roboto',
                    }}
                    viewMode="INTERACTIVE"
                    scaleMode="SPECIFIC"
                    scale={1.0}
                    enableAccessibility={false} 
                    documentMapVisible={false}
                    pageMode="CONTINUOUS_SCROLL" 
                    extention={{}}
                    keepClientAlive={false}
                    />
                    : ""}
                    
                </div>
            </div>
            <ReportScheduleModal
              onClose={toggleDialogSchedule}
              visible={visibleSchedule}
              reportSchedule={reportScheduleQuery.data!} 
              deleteReportSchedule={deleteReportSchedule}
              createReportSchedule={createReportSchedule}
              updateReportSchedule={updateComplianceReports}
              filters={filters}
              type={'ComplianceReport'}
              reportName={'Compliance Report'}
              timezone={scheduleTimezone()}
              />
              <ReportSendEmailModal
              onClose={toggleDialogSendEmail}
              visible={visibleSendEmail}
              toAddresses={reportScheduleQuery.data?.to!} 
              filters={filters}
              timezone={scheduleTimezone()}
              />
            
        </div>
    );
    }
  }
}